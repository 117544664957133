import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import AppProvider from './app/contexts/app';
import AuthProvider from './app/contexts/auth';
import NotificationsProvider from './app/contexts/notifications';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <AppProvider>
    <AuthProvider>
      <NotificationsProvider>
        <App />
      </NotificationsProvider>
    </AuthProvider>
  </AppProvider>,
  document.getElementById('root'),
);

serviceWorker.register();
