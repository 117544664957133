import React, {
  useState,
  createContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import Notification from '../components/Notification';
import useAuth from '../hooks/useAuth';
import api from '../services/api';

export const NotificationsContext = createContext();

function NotificationProvider({ children }) {
  const notificationRef = useRef();
  const { loggedUser } = useAuth();
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState(0);

  useEffect(() => {
    try {
      console.log('🚀 ~ useEffect ~ loggedUser:', loggedUser);
      if (loggedUser?._id) {
        const fetchNotifications = async () => {
          setLoading(true);
          const { data } = await api.get('/notifications');
          setNotifications(data?.docs || []);
          setUnreadNotifications(data?.unreadNotifications || 0);
          setLoading(false);
        };
        fetchNotifications();
      }
    } catch (error) {
      if (error && error.response && error.response.data) {
        const { message } = error.response.data;
        notificationRef.current.notify({
          message,
          color: 'danger',
        });
      }

      notificationRef.current.notify({
        message: 'Algo inesperado aconteceu e não foi possível carregar as notificações',
        color: 'danger',
      });
    } finally {
      setLoading(false);
    }
  }, [loggedUser]);

  const onDelete = async (n) => {
    try {
      setLoading(true);
      await api.delete(`/notifications/${n._id}`);
      setNotifications(notifications.filter((_n) => _n._id !== n._id));
      notificationRef.current.notify({
        message: 'Notificação deletada com sucesso',
        color: 'success',
      });
      setLoading(false);
    } catch (error) {
      if (error && error.response && error.response.data) {
        const { message } = error.response.data;
        notificationRef.current.notify({
          message,
          color: 'danger',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  // /notifications/:id/status/read
  const onMarkAsRead = async (n) => {
    try {
      setLoading(true);
      await api.put(`/notifications/${n._id}/status/read`);
      setNotifications(notifications.map((_n) => (_n._id === n._id ? { ..._n, status: 'lido' } : _n)));
      setUnreadNotifications((prev) => prev - 1);
      notificationRef.current.notify({
        message: 'Notificações atualizadas com sucesso',
        color: 'success',
      });
    } catch (err) {
      if (err && err.response && err.response.data) {
        const { message } = err.response.data;
        notificationRef.current.notify({
          message,
          color: 'danger',
        });

        return;
      }

      notificationRef.current.notify({
        message: 'Algo inesperado aconteceu e não foi possível salvar',
        color: 'danger',
      });
    } finally {
      setLoading(false);
    }
  };

  const value = useMemo(
    () => ({
      loading,
      notifications,
      unreadNotifications,
      onDelete,
      onMarkAsRead,
    }),
    [
      loading,
      notifications,
      unreadNotifications,
      onDelete,
      onMarkAsRead,
    ],
  );

  return (
    <NotificationsContext.Provider value={value}>
      <Notification ref={notificationRef} />
      {children}
    </NotificationsContext.Provider>
  );
}

export default NotificationProvider;
